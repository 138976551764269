<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <v-col sm="8" md="8" lg="8">
            <v-layout align-center fill-height>
              {{
                $t("Do you wish") +
                " " +
                validation.Customer.FirstName +
                " " +
                validation.Customer.LastName +
                " " +
                $t("change from current type to new type?")
              }}
            </v-layout>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row no-gutters class="mb-4">
          <v-col>
            <p class="mb-0">
              {{
                $t("Name") +
                ": " +
                validation.Customer.FirstName +
                " " +
                validation.Customer.LastName
              }}
            </p>
            <p class="mb-0">
              {{
                $t("BirthDate") +
                ": " +
                helpers.changeTimeToReadebleDMY(validation.Customer.BirthDate)
              }}
            </p>
            <p class="mb-0">
              {{ $t("Email") + ": " + validation.Customer.Email }}
            </p>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="3">
            <p class="text-subtitle-1 mb-1 font-weight-bold">
              {{ $t("Current type") }}
            </p>
            <p class="mb-0">
              {{ getType }}
            </p>
          </v-col>
          <v-col cols="2">
            <v-layout align-center fill-height>
              <v-icon> mdi-arrow-right </v-icon>
            </v-layout>
          </v-col>
          <v-col cols="4">
            <p class="text-subtitle-1 mb-1 font-weight-bold">
              {{ $t("Nieuw type") }}
            </p>

            <p class="mb-0">
              {{ $t("Type") + ": " + validation.ToCustomerType.Name }}
            </p>
          </v-col>
          <cardValdiationButtons
            :validation="validation"
            :needsCancelButton="true"
            :ignoreButtonName="$t('Ignore change')"
            :successButtonName="$t('change customer type')"
            @deleteOutArray="$emit('deleteOutArray', $event)"
          />
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import helpers from "../../helpers";
export default {
  props: ["validation"],
  data() {
    return {
      helpers: helpers,
    };
  },
  computed: {
    getType() {
      if (!this.validation.FromCustomerType) {
        return this.$t("No type assigned yet");
      }
      return this.$t("Type") + ": " + this.validation.FromCustomerType.Name;
    },
  },
};
</script>

<style>
</style>